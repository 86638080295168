@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

@layer base {
  .button-purple-shadow {
    @apply shadow-[4px_4px_0_rgb(113,81,240)] bg-white flex justify-center items-center text-center rounded
  }


  .drawer-left-mobile-open {
    @apply transition-opacity opacity-100 duration-500 translate-y-0 translate-x-0;
  }

  .drawer-left-mobile-closed {
    @apply transition-all delay-500 opacity-0 translate-x-full lg:-translate-x-full;
  }

  .drawer-bottom-mobile-open {
    @apply transition-opacity opacity-100 duration-500 translate-y-0 translate-x-0;
  }

  .drawer-bottom-mobile-closed {
    @apply transition-all delay-500 opacity-0 translate-y-full lg:translate-y-0 lg:-translate-x-full;
  }

  .label-yellow-background {
    @apply bg-yellow-really text-center text-black px-3 py-1;
  }

  .button-radio {
    @apply border-t-2 border-t-black text-white w-full bg-slate-800 hover:bg-slate-600 ease-in-out transition-all first:rounded-t-xl last:rounded-b-xl;
  }
  
  .button-radio-disabled {
    @apply border-t-2 border-t-black text-white w-full bg-slate-800 ease-in-out first:rounded-t-xl last:rounded-b-xl select-none;
  }  
}

/* Loader CSS taken from https://cssloaders.github.io/ */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #e6ff50;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Air.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-AirItalic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Thin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-ThinItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-RegularItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik-BlackItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

.checkbox-black {
  @apply accent-black;
  color: black;
}

.checkbox-white {
  @apply accent-gray-400;
  color: #9ca3af;
}