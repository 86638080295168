:root {
  --slider-thumb-size: 30px;
}

.rangeSlider {
  position: relative;
  height: 6px;
  border-radius: 10px;
  text-align: left;
  margin: 32px 0 20px 0;
}

.rangeSlider > div {
  position: absolute;
  left: 13px;
  right: 15px;
  height: 14px;
}

.rangeSlider > div > .inverse-left {
  @apply bg-yellow-really;
  position: absolute;
  left: 0;
  height: 6px;
  border-radius: 10px;
  /* margin: 0 7px; */
}

.rangeSlider > div > .range {
  @apply bg-slate-800;
  position: absolute;
  left: 0;
  height: 6px;
  border-radius: 14px;
}

.rangeSlider > div > .thumb {
  @apply bg-yellow-really rounded-full border-2 border-slate-800 cursor-pointer;
  position: absolute;
  top: -12px;
  z-index: 2;
  height: var(--slider-thumb-size);
  width: var(--slider-thumb-size);
  text-align: left;
  margin-left: -12px;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
}

.rangeSlider > input[type="range"] {
  cursor: pointer;
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 6px;
  top: -2px;
  width: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

div.rangeSlider > input[type="range"]::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div.rangeSlider > input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div.rangeSlider > input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div.rangeSlider > input[type="range"]:focus {
  outline: none;
}

div.rangeSlider > input[type="range"]::-ms-thumb {
  pointer-events: all;
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  border-radius: var(--slider-thumb-size);
  border: 0 none;
  background: red;
}

div.rangeSlider > input[type="range"]::-moz-range-thumb {
  pointer-events: all;
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  border-radius: var(--slider-thumb-size);
  border: 0 none;
  background: red;
}

div.rangeSlider > input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  border-radius: var(--slider-thumb-size);
  border: 0 none;
  background: red;
  -webkit-appearance: none;
}

div.rangeSlider > input[type="range"]::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div.rangeSlider > input[type="range"]::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

div.rangeSlider > input[type="range"]::-ms-tooltip {
  display: none;
}

.rangeSlider > div > .sign {
  opacity: 1;
  position: absolute;
  margin-left: -8px;
  top: 16px;
  z-index: 3;
  color: black;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

.rangeSlider > div > .sign > span {
  @apply text-yellow-really;

  font-size: 1.875em;
  line-height: 1.875em;
  margin-left: -50%;
  font-weight: 500;
}

.rangeSlider:hover > div > .sign {
  opacity: 1;
}
